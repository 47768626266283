<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-tabs v-model="activeTab" class="tabs">
        <el-tab-pane
          :label="__('Configuration')"
          name="configuration"
          style="padding-top: 10px"
        >
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                prop="visual_form_node.data.sms_profile_id"
                :label="__('SMS Profile')"
                class="is-required"
              >
                <el-select
                  v-loading="smsProfilesLoading"
                  v-model="nodeToBind.visual_form_node.data.sms_profile_id"
                  @change="smsProfileChange"
                  default-first-option
                  filterable
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="smsProfile in smsProfiles"
                    :key="smsProfile.sms_profile_id"
                    :label="smsProfile.sms_profile_name"
                    :value="smsProfile.sms_profile_id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                prop="visual_form_node.data.phone_number"
                :label="__('SMS Receiver')"
                class="is-required"
              >
                <div class="tooltips">
                  {{ __("Enter a phone number using a") }}
                  <b>{{ __("variable") }}</b>
                  {{ __("or just using number format like") }}
                  <b>9324234234</b> {{ __("or using combination of both.") }}
                </div>

                <input-variable-popper
                  v-model="nodeToBind.visual_form_node.data.phone_number"
                  :is-text-area="false"
                  :include-prepend="true"
                  prepend-string="+"
                  v-permit="allowedRegex"
                  :include-secure-variables="false"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" style="padding-bottom: 10px">
            <el-col :span="24">
              <el-form-item
                style="position: relative"
                :show-message="!smsContent"
                prop="visual_form_node.data.sms_content"
                :label="__('SMS Content')"
              >
                <el-form-item :error="hasInvalidVariable(smsContent)">
                  <input-variable-popper
                    force-reinitialize
                    v-model="smsContent"
                    :include-secure-variables="false"
                    :placeholder="__('Add text message to send')"
                  />
                </el-form-item>
              </el-form-item>
              <SMSCounter v-model="smsContent" />
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="24" style="min-width:300px">
              <el-form-item
                :label="__('If Opened, Goto Node')"
                prop="open_link_node"
                class="is-required"
              >
                <goto-node-condition
                  v-model="openedCondition"
                  condition-keyword="Opened"
                  :goto-options="generateGotoOptions"
                  style="width: 100%;"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24" style="min-width:300px">
              <el-form-item
                :label="__('Otherwise Goto Node')"
                prop="otherwise"
                class="is-required"
              >
                <goto-node-condition
                  v-model="otherwiseCondition"
                  condition-keyword="Otherwise"
                  :goto-options="generateGotoOptions"
                  style="width: 100%;"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Prompt')"
          name="prompt"
          style="padding-top: 10px"
        >
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                :label="__('Prompt')"
                prop="visual_form_node.data.prompt_text"
              >
                <input-variable-popper
                  :value="nodeToBind.visual_form_node.data.prompt_text"
                  is-content-editable
                  @input="handleUpdateText('prompt_text', ...arguments)"
                  :is-text-area="false"
                  include-prompts
                  include-audio-variables
                  :include-secure-variables="false"
                  :ats="getOptionsInitiators"
                  popper-class="prompt-node-popper"
                  class="promptEditor"
                  :popper-offset="-400"
                />
                <audio-player
                  class="audio-player"
                  :disabled="!promptText"
                  @get-preview="
                    generateAudio(
                      'promptText',
                      'generatingAudio',
                      'promptAudioFile',
                      'promptAtAudioFileCreation'
                    )
                  "
                  :show-reload="promptContentChanged"
                  :generating-audio="generatingAudio"
                  :file="promptAudioFile"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                prop="visual_form_node.data.prompt_timeout"
                :label="__('Prompt Timeout (Seconds)')"
              >
                <el-input-number
                  v-model="nodeToBind.visual_form_node.data.prompt_timeout"
                  :precision="0"
                  :min="10"
                  :step="1"
                  :max="60"
                  :step-strictly="true"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Form Fields')"
          name="formFields"
          style="padding-top: 10px"
        >
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                prop="visual_form_node.data.visual_form_node_attachment_file.data"
                :label="__('Attachments')"
              >
                <visual-form-node-attachment
                  v-model="visualFormAttachments"
                  :filter-default-media-options="attachmentMediaOptions"
                  @invalid-variables="
                    updateInvalidVariableMap($event, 'attachments')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                prop="visual_form_node.data.visual_form_node_fields.data"
                :label="__('Form Fields')"
              >
                <node-content-form-fields
                  v-model="visualFormNodeFields"
                  :gotoOptions="generateGotoOptions"
                  @removed-msg-btn="handleFieldRemoved($event)"
                  style="width: 100%;"
                  @invalid-variables="
                    updateInvalidVariableMap($event, 'form_fields')
                  "
                >
                </node-content-form-fields>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                prop="visual_form_node.data.visual_form_node_contents.data"
                :label="__('Buttons')"
              >
                <node-content-buttons
                  v-model="visualFormNodeContents"
                  :gotoOptions="generateGotoOptions"
                  @removed-msg-btn="handleBtnRemoved($event)"
                  style="width: 100%;"
                  @invalid-variables="
                    updateInvalidVariableMap($event, 'buttons')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import _ from "lodash";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import AudioPlayer from "@/components/AudioPlayer";
import {
  filterRowsIfEveryKeyValueIsAbsent,
  filterRowsIfSomeKeyValueIsAbsent,
  getSubKeyObject
} from "@/utils/collection";
import { mapActions, mapGetters, mapState } from "vuex";
import { numberOrVariable } from "@/utils/regex";
import GotoNodeCondition from "../components/GotoNodeCondition";
import { findKeywordCondition } from "@/utils/collection";
import { NODE_TYPES } from "@/constants/nodes";
import NodeContentButtons from "@/views/build/callflow/components/node-type-forms/components/NodeContentButtons";
import NodeContentFormFields from "@/views/build/callflow/components/node-type-forms/components/NodeContentFormFields";
import VisualFormNodeAttachment from "@/views/build/callflow/components/node-type-forms/components/VisualFormNodeAttachment";
import { v4 as uuidv4 } from "uuid";
import { validPhoneNumber } from "@/utils/validate";
import SMSCounter from "@/views/build/callflow/components/node-type-forms/components/SMSCounter";

const RULE_TYPE_BUTTON = "BUTTON";
const RULE_TYPE_FORM_FIELD = "FORM_FIELD";
const RULE_TYPE_FORM_FIELD_SECONDARY = "FORM_FIELD_SECONDARY";

import { VISUAL_FORM_FIELD_TYPE_CONST } from "@/constants/visualFormFieldTypes.js";

export default {
  mixins: [BaseNode, GenerateAudio],
  name: "VisualFormNode",
  components: {
    VisualFormNodeAttachment,
    NodeContentFormFields,
    NodeContentButtons,
    InputVariablePopper,
    AudioPlayer,
    GotoNodeCondition,
    SMSCounter
  },

  data() {
    const validateOtherwiseNode = (rule, value, callback) => {
      const otherwiseNode = _.find(
        this.nodeToBind.visual_form_node.data.keyword_conditions.data,
        { keyword: "Otherwise" }
      );
      if (!otherwiseNode) {
        callback(__("Otherwise node cannot be empty"));
      } else {
        if (otherwiseNode.node_id === -1 && !otherwiseNode.node_name) {
          callback(__("Otherwise node cannot be empty"));
        } else if (otherwiseNode.node_id === -1) {
          if (otherwiseNode.node_name.toLowerCase() === "disconnect") {
            callback(
              " '" +
                `${otherwiseNode.node_name}` +
                "' " +
                __("cannot be used as node name")
            );
          }
          !_.map(this.getValidNodes, "node_name").includes(
            otherwiseNode.node_name
          )
            ? callback()
            : callback(__("Node already exists"));
        } else {
          callback();
        }
      }
    };

    /**
     * validate open link node
     * @param rule
     * @param value
     * @param callback
     */
    const validateOpenLinkNode = (rule, value, callback) => {
      const openLinkNode = _.find(
        this.nodeToBind.visual_form_node.data.keyword_conditions.data,
        { keyword: "Opened" }
      );
      if (!openLinkNode) {
        callback(__("Node for open link cannot be empty"));
      } else {
        if (openLinkNode.node_id === -1 && !openLinkNode.node_name) {
          callback(__("Node for open link cannot be empty"));
        } else if (openLinkNode.node_id === -1) {
          if (openLinkNode.node_name.toLowerCase() === "disconnect") {
            callback(
              " '" +
                `${openLinkNode.node_name}` +
                "' " +
                __("cannot be used as node name")
            );
          }
          !_.map(this.getValidNodes, "node_name").includes(
            openLinkNode.node_name
          )
            ? callback()
            : callback(__("Node already exists"));
        } else {
          callback();
        }
      }
    };

    /**
     * validate attachments
     * @param rule
     * @param value
     * @param callback
     */
    const validateAttachments = (rule, value, callback) => {
      let self = this;
      const errorMessage = __("Missing attachment type or file url");
      if (!_.isEmpty(value)) {
        // if _.some return true, it means its invalid
        let isValid = !_.some(value, attachment => {
          // if all the relevant fields are empty, dont consider it to be invalid
          if (!attachment.attachment_type && !attachment.file_url) {
            return false;
          } else {
            // if something is missing
            return !attachment.attachment_type || !attachment.file_url;
          }
        });
        if (isValid && self.invalidVariablesMap["attachments"]) {
          callback(__("Invalid variables found in Attachments content"));
          return true;
        }
        if (isValid) {
          if (this.isFormFieldsTabEmpty) {
            callback(this.emptyFormFieldsTabMessage);
          } else {
            callback();
          }
        } else {
          callback(errorMessage);
        }
      } else {
        if (this.isFormFieldsTabEmpty) {
          callback(this.emptyFormFieldsTabMessage);
        } else {
          callback();
        }
      }
    };

    /**
     * validate sms profile
     * @param rule
     * @param value
     * @param cb
     */
    const smsProfileValidator = (rule, value, cb) => {
      if (!value) {
        cb(__("SMS Profile cannot be empty"));
      } else {
        cb();
      }
    };

    /**
     * validate buttons mandatory fields
     * @param rule
     * @param value
     * @param callback
     */
    const validateButtons = (rule, value, callback) => {
      let self = this;
      if (
        !_.some(this.visualFormNodeContents, function(obj) {
          // validation passes if all mandatory fields are empty
          if (
            obj.content_label === "" &&
            obj.variable_name === "" &&
            obj.default_value === ""
          ) {
            return false;
          } else if (obj.content_label === "") {
            callback(__("Button cannot be defined with empty label."));
            return true;
          } else if (obj.content_label !== "" && obj.default_value === "") {
            callback(__("Variable cannot be empty."));
            return true;
          } else if (obj.content_label !== "" && obj.variable_name === "") {
            callback(__("Variable cannot be empty."));
            return true;
          } else if (self.invalidVariablesMap["buttons"]) {
            callback(__("Invalid variables found in Buttons content"));
            return true;
          } else if (
            obj.content_label.length >
            NodeContentButtons.BUTTON_LABEL_MAX_LENGTH
          ) {
            callback(
              `Variable button must be less than ${NodeContentButtons.BUTTON_LABEL_MAX_LENGTH} characters.`
            );
            return true;
          }
        })
      ) {
        if (this.isFormFieldsTabEmpty) {
          callback(this.emptyFormFieldsTabMessage);
        } else {
          callback();
        }
      }
    };

    /**
     * validate mandatory form fields
     * @param rule
     * @param value
     * @param callback
     */
    const validateFormFields = (rule, value, callback) => {
      let self = this;
      if (
        !_.some(this.visualFormNodeFields, function(obj) {
          // validation passes if all mandatory fields are empty
          if (obj.field_title === "" && obj.variable_name === "") {
            return false;
          } else if (obj.field_title === "") {
            callback(__("Form field cannot be defined with empty label."));
            return true;
          } else if (obj.field_title !== "" && obj.variable_name === "") {
            callback(__("Variable cannot be empty."));
            return true;
          } else if (self.invalidVariablesMap["form_fields"]) {
            callback(__("Invalid variables found in Form field content"));
            return true;
          }
        })
      ) {
        if (this.isFormFieldsTabEmpty) {
          callback(this.emptyFormFieldsTabMessage);
        } else {
          callback();
        }
      }
    };

    const validateSmsContent = (rule, value, cb) => {
      if (!value) {
        cb();
      } else if (this.hasInvalidVariable(value)) {
        cb(__("Invalid variables found in Messaging content"));
      } else {
        cb();
      }
    };

    const validatePhoneNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error(__("Phone Number cannot be empty")));
      } else if (!validPhoneNumber(value)) {
        callback(new Error(__("Please enter a valid phone number")));
      } else {
        callback();
      }
    };

    return {
      rules: {
        visual_form_node: {
          data: {
            visual_form_node_attachment_file: {
              data: { validator: validateAttachments, trigger: "none" }
            },
            visual_form_node_contents: {
              data: { validator: validateButtons, trigger: "none" }
            },
            visual_form_node_fields: {
              data: { validator: validateFormFields, trigger: "none" }
            },
            sms_profile_id: [
              { validator: smsProfileValidator, trigger: "blur" }
            ],
            phone_number: [
              {
                validator: validatePhoneNumber,
                trigger: "blur"
              }
            ],
            sms_content: [
              {
                required: false,
                message: __("Messaging content cannot be empty"),
                trigger: "blur"
              },
              {
                validator: validateSmsContent
              }
            ]
          }
        },
        otherwise: {
          validator: validateOtherwiseNode,
          trigger: "change"
        },
        open_link_node: {
          validator: validateOpenLinkNode,
          trigger: "change"
        }
      },
      activeTab: "configuration",
      allowedRegex: numberOrVariable,
      invalidVariablesMap: {
        form_fields: false,
        buttons: false,
        attachments: false
      },

      selectedSmsProvider: null,
      selectedSmsProfile: null,
      attachmentMediaOptions: ["image", "document"],
      generatingAudio: false,
      promptAudioFiles: {
        prompt_text: ""
      },
      emptyFormFieldsTabMessage: __("Form fields tab cannot be empty"),
      promptAudioFile: "",
      promptAtAudioFileCreation: ""
    };
  },

  computed: {
    ...mapState("smsprofiles", {
      smsProfiles: state => state.smsProfiles,
      smsProfilesLoading: state => state.loading
    }),

    ...mapGetters("smsprofiles", {
      getSmsProfile: "getSmsProfile"
    }),

    ...mapGetters("smsproviders", {
      getSmsProvider: "getSmsProvider"
    }),

    promptText() {
      return this.nodeToBind.visual_form_node.data.prompt_text;
    },

    /**
     * prompt content changed
     * @returns {boolean}
     */
    promptContentChanged() {
      return this.promptText !== this.promptAtAudioFileCreation;
    },

    visualFormAttachments: {
      get: function() {
        const {
          data: visual_form_attachments
        } = this.nodeToBind.visual_form_node.data.visual_form_node_attachment_file;
        return _.isEmpty(visual_form_attachments)
          ? []
          : _.map(visual_form_attachments, attachment => {
              attachment["msg"] = attachment["msg"] || "";
              return attachment;
            });
      },
      set: function(val) {
        if (
          !_.isEqual(this.visualFormAttachments, val) ||
          _.isEmpty(this.visualFormAttachments)
        ) {
          this.$set(
            this.nodeToBind.visual_form_node.data
              .visual_form_node_attachment_file,
            "data",
            val
          );
        }
      }
    },

    /**
     * all visual form node contents
     * @returns {{data: *[]}|{data: ((function(*, *, *): (boolean|undefined)) | string)[]}}
     */
    allVisualFormNodeContents() {
      return _.isEmpty(
        this.nodeToBind.visual_form_node.data.visual_form_node_contents
      )
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.visual_form_node.data.visual_form_node_contents
                .data,

              visualFormNodeContent => {
                visualFormNodeContent["error"] = "";
                visualFormNodeContent["content_label_error"] = "";

                const subKeys = [
                  "variable_id",
                  "variable_name",
                  "content_label"
                ];
                const subKeysForButtons = [...subKeys, "content_type"];
                // check for message button label conflicts and set error for the conflicting condition
                // if not found then check for keyword conflicts from keyword conditions and set error accordingly
                visualFormNodeContent["content_label_error"] = _.some(
                  this.nodeToBind.visual_form_node.data
                    .visual_form_node_contents.data,
                  condition => {
                    return (
                      !_.isEqual(
                        getSubKeyObject(
                          visualFormNodeContent,
                          subKeysForButtons
                        ),
                        getSubKeyObject(condition, subKeysForButtons)
                      ) &&
                      visualFormNodeContent.content_label.toString().trim() ===
                        condition.content_label.toString().trim()
                    );
                  }
                )
                  ? __("Button Label conflict")
                  : _.some(
                      this.keywordConditionsExcludingButtons,
                      condition => {
                        return (
                          !_.isEqual(
                            getSubKeyObject(visualFormNodeContent, subKeys),
                            getSubKeyObject(condition, subKeys)
                          ) &&
                          visualFormNodeContent.content_label
                            .toString()
                            .trim() ===
                            condition.content_label.toString().trim()
                        );
                      }
                    )
                  ? "Content label conflict"
                  : "";

                visualFormNodeContent["msg"] = !visualFormNodeContent.error
                  ? visualFormNodeContent["msg"] || ""
                  : "";

                return visualFormNodeContent;
              }
            )
          };
    },
    /**
     * all visual form node fields
     * @returns {{data: *[]}|{data: ((function(*, *, *): (boolean|undefined)) | string)[]}}
     */
    allVisualFormNodeFields() {
      return _.isEmpty(
        this.nodeToBind.visual_form_node.data.visual_form_node_fields
      )
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.visual_form_node.data.visual_form_node_fields
                .data,

              visualFormNodeField => {
                visualFormNodeField["error"] = "";
                visualFormNodeField["field_title_error"] = "";

                const subKeys = ["variable_id", "variable_name", "field_title"];
                const subKeysForFields = [...subKeys, "field_title"];
                // check for message button label conflicts and set error for the conflicting condition
                // if not found then check for keyword conflicts from keyword conditions and set error accordingly
                visualFormNodeField["field_title_error"] = _.some(
                  this.nodeToBind.visual_form_node.data.visual_form_node_fields
                    .data,
                  condition => {
                    return (
                      !_.isEqual(
                        getSubKeyObject(visualFormNodeField, subKeysForFields),
                        getSubKeyObject(condition, subKeysForFields)
                      ) &&
                      visualFormNodeField.field_title.toString().trim() ===
                        condition.field_title.toString().trim()
                    );
                  }
                )
                  ? __("Field Label conflict")
                  : _.some(
                      this.keywordConditionsExcludingButtons,
                      condition => {
                        return (
                          !_.isEqual(
                            getSubKeyObject(visualFormNodeField, subKeys),
                            getSubKeyObject(condition, subKeys)
                          ) &&
                          visualFormNodeField.field_title.toString().trim() ===
                            condition.field_title.toString().trim()
                        );
                      }
                    )
                  ? __("Field label conflict")
                  : "";

                visualFormNodeField["msg"] = !visualFormNodeField.error
                  ? visualFormNodeField["msg"] || ""
                  : "";

                return visualFormNodeField;
              }
            )
          };
    },
    visualFormNodeContents: {
      get: function() {
        const {
          data: visual_form_node_contents
        } = this.allVisualFormNodeContents;
        return _.isEmpty(visual_form_node_contents)
          ? []
          : visual_form_node_contents;
      },
      set: function(val) {
        let updatedVal = _.cloneDeep(val);
        const visual_form_node_contents = this.visualFormNodeContents;
        if (
          // update only if there is a change in keyword conditions excluding the otherwise node
          !_.isEqual(visual_form_node_contents, val) ||
          _.isEmpty(visual_form_node_contents)
        ) {
          // update the variable rules for the rule type
          this.updateVariableRules(updatedVal, RULE_TYPE_BUTTON);

          this.$set(
            this.nodeToBind.visual_form_node.data.visual_form_node_contents,
            "data",
            val
          );
        }
      }
    },
    visualFormNodeFields: {
      get: function() {
        const { data: visual_form_node_fields } = this.allVisualFormNodeFields;
        return _.isEmpty(visual_form_node_fields)
          ? []
          : visual_form_node_fields;
      },
      set: function(val) {
        let updatedVal = _.cloneDeep(val);
        const visual_form_node_fields = this.visualFormNodeFields;
        if (
          // update only if there is a change in keyword conditions excluding the otherwise node
          !_.isEqual(visual_form_node_fields, val) ||
          _.isEmpty(visual_form_node_fields)
        ) {
          // update the variable rules for the rule type
          this.updateVariableRules(updatedVal, RULE_TYPE_FORM_FIELD);
          this.updateVariableRules(updatedVal, RULE_TYPE_FORM_FIELD_SECONDARY);

          this.$set(
            this.nodeToBind.visual_form_node.data.visual_form_node_fields,
            "data",
            val
          );
        }
      }
    },

    /**
     * options initiators
     * @returns {string[]}
     */
    getOptionsInitiators() {
      return ["{{", "[["];
    },

    allKeywordConditions() {
      return _.isEmpty(this.nodeToBind.visual_form_node.data.keyword_conditions)
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.visual_form_node.data.keyword_conditions.data,

              keywordCondition => {
                keywordCondition["error"] = "";
                if (keywordCondition.node_id === -1) {
                  keywordCondition["error"] = !_.map(
                    this.getValidNodes,
                    "node_name"
                  ).includes(keywordCondition.node_name)
                    ? ""
                    : __("Node") +
                      " '" +
                      `${keywordCondition.node_name}` +
                      "' " +
                      __("cannot be used here");
                }
                if (
                  keywordCondition.node_id === -1 &&
                  keywordCondition.node_name.toLowerCase() === "disconnect"
                ) {
                  keywordCondition["error"] = __("Invalid node name");
                }

                keywordCondition["keyword_error"] = "";
                keywordCondition["msg"] = !keywordCondition.error
                  ? keywordCondition["msg"] || ""
                  : "";

                return keywordCondition;
              }
            )
          };
    },

    /**
     * user clicks on link
     */
    openedCondition: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? {}
          : findKeywordCondition(_.cloneDeep(keyword_conditions), "Opened");
      },
      set: function(openedCondition) {
        if (
          // append true node to keyword conditions only if true node content changes
          !_.isEqual(this.openedCondition, openedCondition)
        ) {
          let { data: keyword_conditions } = this.allKeywordConditions;
          if (!_.isEmpty(keyword_conditions)) {
            let index = _.findIndex(keyword_conditions, function(condition) {
              return condition.keyword === openedCondition.keyword;
            });
            if (index !== -1) {
              keyword_conditions.splice(index, 1);
            }
          } else {
            keyword_conditions = [];
          }
          keyword_conditions.push(openedCondition);
          this.$set(
            this.nodeToBind.visual_form_node.data.keyword_conditions,
            "data",
            keyword_conditions
          );
        }
      }
    },

    /**
     * otherwise condition when user does not click on link
     */
    otherwiseCondition: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? {}
          : findKeywordCondition(_.cloneDeep(keyword_conditions), "Otherwise");
      },
      set: function(otherwiseCondition) {
        if (
          // append otherwise node to keyword conditions only if otherwise node content changes
          !_.isEqual(this.otherwiseCondition, otherwiseCondition)
        ) {
          let { data: keyword_conditions } = this.allKeywordConditions;
          if (!_.isEmpty(keyword_conditions)) {
            let index = _.findIndex(keyword_conditions, function(condition) {
              return condition.keyword === otherwiseCondition.keyword;
            });
            if (index !== -1) {
              keyword_conditions.splice(index, 1);
            }
          } else {
            keyword_conditions = [];
          }
          keyword_conditions.push(otherwiseCondition);
          this.$set(
            this.nodeToBind.visual_form_node.data.keyword_conditions,
            "data",
            keyword_conditions
          );
        }
      }
    },

    smsContent: {
      get: function() {
        return this.nodeToBind.visual_form_node.data.sms_content || "";
      },
      set: function(val) {
        this.nodeToBind.visual_form_node.data.sms_content = val;
      }
    },

    /**
     * is the form fields tab empty
     * @returns {boolean}
     */
    isFormFieldsTabEmpty() {
      // checking if any of the items have at least one non empty required field
      // if not the form fields is considered empty
      return (
        !_.some(this.visualFormNodeContents, function(obj) {
          return (
            obj.content_label !== "" ||
            obj.variable_name !== "" ||
            obj.default_value !== ""
          );
        }) &&
        !_.some(this.visualFormNodeFields, function(obj) {
          return obj.field_title !== "" || obj.variable_name !== "";
        }) &&
        !_.some(this.visualFormAttachments, function(obj) {
          return obj.attachment_type !== "" || obj.file_url !== "";
        })
      );
    }
  },
  methods: {
    ...mapActions("smsprofiles", {
      getSmsProfiles: "getSmsProfiles"
    }),

    ...mapActions("smsproviders", {
      getSmsProviders: "getSmsProviders"
    }),

    /**
     * update invalid variables
     * @param event
     * @param key
     */
    updateInvalidVariableMap(event, key) {
      this.invalidVariablesMap[key] = event;
    },

    /**
     * Convert input to string then trim it
     * @returns {string}
     */
    stringTrimmer(input) {
      if (input === undefined) {
        return "";
      }

      return input.toString().trim();
    },

    /**
     * check the form for errors before hitting submit, need those below to pass be other
     * @returns {boolean}
     */
    formHasErrors() {
      return false;
    },
    /**
     * get the node data in the correct structure for submission
     * @returns {{}}
     */
    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);

      //cleanup message attachments
      const visualFormAttachments = _.map(
        filterRowsIfEveryKeyValueIsAbsent(
          _.map(this.visualFormAttachments, messageAttachment => ({
            ...messageAttachment,
            attachment_id: messageAttachment.attachment_id,
            node_id: messageAttachment.node_id,
            attachment_type: messageAttachment.attachment_type
              .toString()
              .trim(),
            file_url: messageAttachment.file_url.toString().trim(),
            file_id: messageAttachment.file_id,
            file_name: messageAttachment.file_name
          })),
          "attachment_type,file_url,file_id,file_name"
        ),
        attachment => {
          const {
            attachment_id,
            node_id,
            attachment_type,
            file_url,
            file_id,
            file_name
          } = attachment;
          return {
            attachment_id,
            node_id,
            attachment_type,
            file_url,
            file_id,
            file_name
          };
        }
      );

      this.$set(
        nodeToCleanUp.visual_form_node.data.visual_form_node_attachment_file,
        "data",
        visualFormAttachments
      );

      // cleanup visual form buttons
      let visualFormNodeContents = _.map(
        filterRowsIfSomeKeyValueIsAbsent(
          _.map(this.allVisualFormNodeContents.data, visualFormNodeContent => ({
            ...visualFormNodeContent,
            content_id: visualFormNodeContent.content_id,
            node_id: visualFormNodeContent.node_id,
            content_label: visualFormNodeContent.content_label
              .toString()
              .trim(),
            content_type: visualFormNodeContent.content_type.toString().trim(),
            content_icon: visualFormNodeContent.content_icon.toString().trim()
          })),
          "content_label"
        ),
        button => {
          const {
            content_id,
            node_id,
            content_label,
            content_type,
            content_icon
          } = button;
          return {
            content_id,
            node_id,
            content_label,
            content_type,
            content_icon
          };
        }
      );

      if (visualFormNodeContents.length === 0) {
        this.$delete(
          nodeToCleanUp.visual_form_node.data,
          "visual_form_node_contents"
        );
      } else {
        this.$set(
          nodeToCleanUp.visual_form_node.data.visual_form_node_contents,
          "data",
          visualFormNodeContents
        );
      }

      // cleanup visual form fields
      let visualFormNodeFields = _.map(
        filterRowsIfSomeKeyValueIsAbsent(
          _.map(this.allVisualFormNodeFields.data, visualFormNodeField => ({
            ...visualFormNodeField,
            field_id: visualFormNodeField.field_id,
            node_id: visualFormNodeField.node_id,
            field_title: visualFormNodeField.field_title.toString().trim(),
            field_placeholder: visualFormNodeField.field_placeholder
              .toString()
              .trim(),
            field_type: this.stringTrimmer(visualFormNodeField.field_type),
            field_description: this.stringTrimmer(
              visualFormNodeField.field_description
            )
          })),
          "field_title"
        ),
        field => {
          const {
            field_id,
            node_id,
            field_title,
            field_placeholder,
            field_type,
            field_description
          } = field;
          return {
            field_id,
            node_id,
            field_title,
            field_placeholder,
            field_type,
            field_description
          };
        }
      );

      if (visualFormNodeFields.length === 0) {
        this.$delete(
          nodeToCleanUp.visual_form_node.data,
          "visual_form_node_fields"
        );
      } else {
        this.$set(
          nodeToCleanUp.visual_form_node.data.visual_form_node_fields,
          "data",
          visualFormNodeFields
        );
      }

      // cleanup the variable rules based off
      const variableRules = _.map(
        filterRowsIfEveryKeyValueIsAbsent(
          _.map(
            nodeToCleanUp.visual_form_node.data.variable_rules.data,
            variableRules => ({
              ...variableRules,
              rule_value: variableRules.rule_value.toString().trim(),
              rule_type: variableRules.rule_type.toString().trim(),
              variable_name: variableRules.variable_name.toString().trim()
            })
          ),
          "rule_value,variable_name"
        ),
        rule => {
          const {
            variable_id,
            rule_type,
            rule_value,
            variable_name,
            default_value
          } = rule;
          return {
            variable_id,
            rule_type,
            rule_value,
            variable_name,
            default_value
          };
        }
      );

      this.$set(
        nodeToCleanUp.visual_form_node.data.variable_rules,
        "data",
        variableRules
      );

      // cleanup keyword_matches
      const keywordConditions = _.map(
        filterRowsIfSomeKeyValueIsAbsent(
          _.map(this.allKeywordConditions.data, keywordCondition => ({
            ...keywordCondition,
            keyword: keywordCondition.keyword.toString().trim(),
            node_name: keywordCondition.node_name.toString().trim()
          })),
          "keyword,node_name"
        ),
        condition => {
          const { keyword, node_id, node_name } = condition;
          return {
            keyword,
            node_id,
            node_name
          };
        }
      );

      this.$set(
        nodeToCleanUp.visual_form_node.data.keyword_conditions,
        "data",
        keywordConditions
      );

      return nodeToCleanUp;
    },

    /**
     * clean up node data
     */
    cleanUpNode() {
      if (!this.formHasErrors()) {
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    },

    /**
     * override create or edit, because we need to clean up node on submit once validation passes
     */
    createOrEditNode() {
      if (!this.isTaskReadOnly) {
        this.attemptedToSubmit = true;
        this.$refs.nodeForm.validate((valid, errors) => {
          if (valid) {
            this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
            this.process({
              node: this.nodeToBind,
              nodeInContext: this.node
            })
              .then(async () => {
                this.newVariableCreated
                  ? await this.forceFetchVariables()
                  : null;
                this.setClickedNode(null);
                this.toggleNodeSubmit(false);
              })
              .catch(() => {
                // this.newVariableCreated = false;
                this.toggleNodeSubmit(false);
              });
          } else {
            this.showErrorMessages(errors);
            this.toggleNodeSubmit(false);
            // this.newVariableCreated = false;
            return false;
          }
        });
      }
    },

    /**
     * on change update sms profile information
     * @param smsProfileId
     */
    smsProfileChange(smsProfileId) {
      if (smsProfileId && !this.smsProfilesLoading) {
        this.selectedSmsProfile = _.cloneDeep(this.getSmsProfile(smsProfileId));
        this.selectedSmsProvider = _.cloneDeep(
          this.getSmsProvider(this.selectedSmsProfile.sms_provider_id)
        );
      }
    },

    /**
     * handle update of text for the prompt
     * @param key
     * @param value
     */
    handleUpdateText(key, value) {
      this.$set(this.nodeToBind.visual_form_node.data, key, value);
    },

    /**
     * generate the audio
     * @param promptType
     * @param promptText
     */
    // generateAudio(promptType, promptText) {
    //   if (promptText) {
    //     this.generatingAudio = true;
    //     getAudioPrompt({ prompt_text: promptText, task_id: this.task_id })
    //       .then(({ data }) => {
    //         this.$set(this.promptAudioFiles, promptType, data.audio_url);
    //         this.$set(this.promptsAtAudioFileCreation, promptType, promptText);
    //         this.generatingAudio = false;
    //       })
    //       .catch(err => {
    //         this.generatingAudio = false;
    //         this.$message({
    //           type: "error",
    //           message: err.message
    //         });
    //       });
    //   } else {
    //     this.$set(this.promptAudioFiles, promptType, "");
    //   }
    // },

    /**
     * handle button removed event
     * @param content_label
     */
    handleBtnRemoved(content_label) {
      // this needs to be updated to handle button living in visual form content, then call remove variable rule below
      _.remove(
        this.nodeToBind.visual_form_node.data.visual_form_node_contents.data,
        obj => obj.content_label === content_label
      );
      this.removeVariableRule(content_label, RULE_TYPE_BUTTON);
    },

    /**
     * handle field removed event
     * @param field_title
     */
    handleFieldRemoved(field_title) {
      // this needs to be updated to handle field living in visual form content, then call remove variable rule below
      _.remove(
        this.nodeToBind.visual_form_node.data.visual_form_node_fields.data,
        obj => obj.field_title === field_title
      );
      this.removeVariableRule(field_title, RULE_TYPE_FORM_FIELD);
    },

    /**
     * remove a variable rule by its value
     * @param rule_value
     * @param rule_type
     */
    removeVariableRule(rule_value, rule_type) {
      _.remove(
        this.nodeToBind.visual_form_node.data.variable_rules.data,
        obj => obj.rule_value === rule_value && obj.rule_type === rule_type
      );
    },

    /**
     * update variable rules for a specific rule type.
     * @param updatedVariableRuleRelations
     * @param ruleType
     */
    updateVariableRules(
      updatedVariableRuleRelations,
      ruleType = RULE_TYPE_BUTTON
    ) {
      // remove existing variable rules of rule type
      let allOtherVariableRules = _.reject(
        _.cloneDeep(this.nodeToBind.visual_form_node.data.variable_rules.data),
        variableRule => variableRule.rule_type === ruleType
      );

      // update variable rules for the rule type
      let variableRules = _.map(
        _.cloneDeep(updatedVariableRuleRelations),
        updatedVariableRuleRelation => {
          let rule_value = "";
          let default_value = "";

          switch (ruleType) {
            case RULE_TYPE_BUTTON:
              rule_value = updatedVariableRuleRelation.content_label
                .toString()
                .trim();
              default_value = updatedVariableRuleRelation.default_value
                .toString()
                .trim();
              break;
            case RULE_TYPE_FORM_FIELD_SECONDARY:
            case RULE_TYPE_FORM_FIELD:
              rule_value = updatedVariableRuleRelation.field_title
                .toString()
                .trim();
              default_value = updatedVariableRuleRelation.default_value
                .toString()
                .trim();
              break;
            default:
              rule_value = updatedVariableRuleRelation.rule_value
                .toString()
                .trim();
              break;
          }

          return {
            rule_value: rule_value,
            rule_type: ruleType,
            default_value: default_value,
            variable_id:
              ruleType === RULE_TYPE_FORM_FIELD_SECONDARY
                ? updatedVariableRuleRelation.secondary_variable_id
                : updatedVariableRuleRelation.variable_id,
            variable_name:
              ruleType === RULE_TYPE_FORM_FIELD_SECONDARY
                ? this.stringTrimmer(
                    updatedVariableRuleRelation.secondary_variable_name
                  )
                : this.stringTrimmer(updatedVariableRuleRelation.variable_name),
            node_id:
              this.nodeToBind.node_id !== undefined
                ? this.nodeToBind.node_id
                : -1
          };
        }
      );

      let combinedVariableRules = _.union(allOtherVariableRules, variableRules);

      // update the new variable created flag, filter out the empty record by checking on variable name
      this.newVariableCreated = _.some(
        combinedVariableRules,
        variable => variable.variable_id === -1 && variable.variable_name !== ""
      );

      // merge variable rules of rule type with all other variable rules
      this.$set(
        this.nodeToBind.visual_form_node.data.variable_rules,
        "data",
        combinedVariableRules
      );
    },

    /**
     * Initialize all field type to the default field type if field type is empty
     */
    initializeFieldType() {
      this.nodeToBind.visual_form_node.data.visual_form_node_fields.data.forEach(
        fieldData => {
          if (
            fieldData.field_type === undefined ||
            fieldData.field_type === ""
          ) {
            fieldData.field_type = VISUAL_FORM_FIELD_TYPE_CONST.DEFAULT;
          }
        }
      );
    }
  },
  created() {
    // update this to add visual form node
    this.getSmsProviders();
    this.getSmsProfiles();

    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.visual_form_node)
    ) {
      this.$set(this.nodeToBind, "visual_form_node", {});
      this.$set(this.nodeToBind.visual_form_node, "data", {});
      this.$set(this.nodeToBind.visual_form_node.data, "sms_profile_id", null);
      this.$set(this.nodeToBind.visual_form_node.data, "prompt_text", "");
      this.$set(this.nodeToBind.visual_form_node.data, "prompt_timeout", 10);
      this.$set(this.nodeToBind.visual_form_node.data, "phone_number", "");
      this.$set(this.nodeToBind.visual_form_node.data, "sms_content", "");
      if (
        _.isEmpty(
          this.nodeToBind.visual_form_node.data.visual_form_node_attachment_file
        )
      ) {
        this.$set(
          this.nodeToBind.visual_form_node.data,
          "visual_form_node_attachment_file",
          {}
        );
        this.$set(
          this.nodeToBind.visual_form_node.data
            .visual_form_node_attachment_file,
          "data",
          []
        );
      }

      if (
        _.isEmpty(
          this.nodeToBind.visual_form_node.data.visual_form_node_contents
        )
      ) {
        this.$set(
          this.nodeToBind.visual_form_node.data,
          "visual_form_node_contents",
          {}
        );

        this.$set(
          this.nodeToBind.visual_form_node.data.visual_form_node_contents,
          "data",
          []
        );
      }

      if (
        _.isEmpty(this.nodeToBind.visual_form_node.data.visual_form_node_fields)
      ) {
        this.$set(
          this.nodeToBind.visual_form_node.data,
          "visual_form_node_fields",
          {}
        );

        this.$set(
          this.nodeToBind.visual_form_node.data.visual_form_node_fields,
          "data",
          []
        );
      }

      this.$set(this.nodeToBind.visual_form_node.data, "variable_rules", {});
      this.$set(
        this.nodeToBind.visual_form_node.data.variable_rules,
        "data",
        []
      );

      if (_.isEmpty(this.nodeToBind.visual_form_node.data.keyword_conditions)) {
        this.$set(
          this.nodeToBind.visual_form_node.data,
          "keyword_conditions",
          {}
        );
        this.$set(
          this.nodeToBind.visual_form_node.data.keyword_conditions,
          "data",
          []
        );
      }
      this.$set(this.nodeToBind, "node_type", NODE_TYPES.VISUAL_FORM.NODE_TYPE);
    } else {
      if (!this.nodeToBind.visual_form_node.data.sms_profile_id) {
        this.$set(
          this.nodeToBind.visual_form_node.data,
          "sms_profile_id",
          null
        );
      }
      if (
        _.isEmpty(
          this.nodeToBind.visual_form_node.data.visual_form_node_attachment_file
        )
      ) {
        this.$set(
          this.nodeToBind.visual_form_node.data,
          "visual_form_node_attachment_file",
          {}
        );
      }

      if (
        _.isEmpty(
          this.nodeToBind.visual_form_node.data.visual_form_node_contents
        )
      ) {
        this.$set(
          this.nodeToBind.visual_form_node.data,
          "visual_form_node_contents",
          {}
        );
        this.$set(
          this.nodeToBind.visual_form_node.data.visual_form_node_contents,
          "data",
          []
        );
      } else {
        // add the fields back in to visual_form_node_contents which aren't stored in the backend, but present in variable rule so visualFormNodeContents works
        let updatedVisualFormNodeContents = _.map(
          _.cloneDeep(
            this.nodeToBind.visual_form_node.data.visual_form_node_contents.data
          ),
          visualFormNodeContent => {
            let variableRule = _.find(
              this.nodeToBind.visual_form_node.data.variable_rules.data,
              variableRule => {
                return (
                  variableRule.rule_value ===
                    visualFormNodeContent.content_label &&
                  variableRule.rule_type === RULE_TYPE_BUTTON
                );
              }
            );

            if (variableRule) {
              visualFormNodeContent.variable_id = variableRule.variable_id;
              visualFormNodeContent.variable_name = variableRule.variable_name;
              visualFormNodeContent.default_value = variableRule.default_value;
              visualFormNodeContent.rule_type = variableRule.rule_type;
            } else {
              visualFormNodeContent.variable_id = -1;
              visualFormNodeContent.variable_name = "";
              visualFormNodeContent.default_value = "";
              visualFormNodeContent.rule_type = RULE_TYPE_BUTTON;
            }

            // need this for row id on buttons table, wont be saved to backend
            if (visualFormNodeContent.uuid == undefined) {
              visualFormNodeContent.uuid = uuidv4();
            }

            return visualFormNodeContent;
          }
        );

        this.$set(
          this.nodeToBind.visual_form_node.data.visual_form_node_contents,
          "data",
          updatedVisualFormNodeContents
        );
      }

      if (
        _.isEmpty(this.nodeToBind.visual_form_node.data.visual_form_node_fields)
      ) {
        this.$set(
          this.nodeToBind.visual_form_node.data,
          "visual_form_node_fields",
          {}
        );
        this.$set(
          this.nodeToBind.visual_form_node.data.visual_form_node_fields,
          "data",
          []
        );
      } else {
        // add the fields back in to visual_form_node_contents which aren't stored in the backend, but present in variable rule so visualFormNodeContents works
        let updatedVisualFormNodeFields = _.map(
          _.cloneDeep(
            this.nodeToBind.visual_form_node.data.visual_form_node_fields.data
          ),
          visualFormNodeField => {
            let variableRule = _.find(
              this.nodeToBind.visual_form_node.data.variable_rules.data,
              variableRule => {
                return (
                  variableRule.rule_value === visualFormNodeField.field_title &&
                  variableRule.rule_type === RULE_TYPE_FORM_FIELD
                );
              }
            );
            let secondaryVariableRule = _.find(
              this.nodeToBind.visual_form_node.data.variable_rules.data,
              variableRule => {
                return (
                  variableRule.rule_value === visualFormNodeField.field_title &&
                  variableRule.rule_type === RULE_TYPE_FORM_FIELD_SECONDARY
                );
              }
            );

            if (variableRule) {
              visualFormNodeField.variable_id = variableRule.variable_id;
              visualFormNodeField.variable_name = variableRule.variable_name;
              visualFormNodeField.default_value = variableRule.default_value;
              visualFormNodeField.rule_type = variableRule.rule_type;
            } else {
              visualFormNodeField.variable_id = -1;
              visualFormNodeField.variable_name = "";
              visualFormNodeField.default_value = "";
              visualFormNodeField.rule_type = RULE_TYPE_FORM_FIELD;
            }

            if (secondaryVariableRule) {
              visualFormNodeField.secondary_variable_id =
                secondaryVariableRule.variable_id !== undefined
                  ? secondaryVariableRule.variable_id
                  : -1;
              visualFormNodeField.secondary_variable_name =
                secondaryVariableRule.variable_name !== undefined
                  ? secondaryVariableRule.variable_name
                  : "";
            } else {
              visualFormNodeField.secondary_variable_id = -1;
              visualFormNodeField.secondary_variable_name = "";
            }

            // need this for row id on form fields table, wont be saved to backend
            if (visualFormNodeField.uuid === undefined) {
              visualFormNodeField.uuid = uuidv4();
            }

            return visualFormNodeField;
          }
        );

        this.$set(
          this.nodeToBind.visual_form_node.data.visual_form_node_fields,
          "data",
          updatedVisualFormNodeFields
        );

        // Make sure default field type is defined
        this.initializeFieldType();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/node_common.scss";

.tabs ::v-deep .el-tabs__item.is-active {
  color: $--color-messaging_conversation-node;
}

.tabs ::v-deep .el-tabs__item:hover {
  color: $--color-messaging_conversation-node;
}

.tabs ::v-deep .el-tabs__active-bar {
  background-color: $--color-messaging_conversation-node;
}

.text-input ::v-deep textarea {
  resize: none;
}

.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}

.msg-counter {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.tabs ::v-deep .el-scrollbar__bar {
  opacity: 1;
}

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;

    &:focus {
      border: 1px solid black;
    }
  }
}
</style>
